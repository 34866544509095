import styled from 'styled-components';

export const NotFoundContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 2100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NotFoundCard = styled.div`
  background-color: #fff;
  border-radius: 30px;
  box-shadow: inset 0 0 0 1px #f9f9fb, 0 8px 24px rgb(129 129 140 / 8%), 0 56px 52px rgb(129 129 140 / 8%);
  padding: 40px 20px;
  min-width: 300px;
`

export const BtnWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: flex-end;
`
