import styled from 'styled-components';
import { NavLink as LinkRR } from 'react-router-dom';

export const Bar = styled.nav`
  position: absolute;
  top: 0;
  background: transparent;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`

export const AppBarContainer = styled.div`
  display: flex;
  height: 70px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1300px;
  align-items: center;
`

export const BarLogo = styled(LinkRR)`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: ${props => props.isRTL ? '0px' : '24px'};
  margin-right: ${props => props.isRTL ? '24px' : '0px'};
`
