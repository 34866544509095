import styled from 'styled-components';
import breakpoint from '../../utils/breakpoint';

export const JumboContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  height: 700px;
  position: relative;
  z-index: 1;

  @media screen and (${breakpoint.devices_max.xs}) {
    height: 760px;
  }
`

export const JumboBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const JumboImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  background: #fff;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
`

export const JumboDiv = styled.div`
  z-index: 5;
  width: 100%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
`

export const JumboContent = styled.div`
  max-width: 690px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const JumboTitle = styled.h1`
  color: #fff;
  font-size: 60px;
  font-family: 'DM Sans', 'Poppins', sans-serif;
  text-align: center;

  @media screen and (${breakpoint.devices_max.sm}) {
    font-size: 54px;
  }

  @media screen and (${breakpoint.devices_max.xs}) {
    font-size: 45px;
  }
`

export const JumboSubtitle = styled.p`
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 25px;
  font-weight: 200;
  text-align: center;

  @media screen and (${breakpoint.devices_max.sm}) {
    font-size: 25px;
  }

  @media screen and (${breakpoint.devices_max.xs}) {
    font-size: 19px;
  }
`

export const ThirdText = styled.p`
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 23px;
  font-weight: 300;
  text-align: center;

  @media screen and (${breakpoint.devices_max.sm}) {
    font-size: 23px;
  }

  @media screen and (${breakpoint.devices_max.xs}) {
    font-size: 20px;
  }
`

export const JumboWaitlistDiv = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  max-width: 440px;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #f9f9fb, 0 8px 24px rgb(129 129 140 / 8%), 0 56px 52px rgb(129 129 140 / 8%);
  border-radius: 30px;
  padding: 0px 20px;
`

export const JumboWaitlistInput = styled.input`
  font-family: inherit;
  font-size: 17px;
  background-color: transparent;
  color: #000;
  padding: 20px 0px;
  outline: none;
  border: none;
  width: 100%;

  &::placeholder {
    color: rgba(0, 0, 0, 0.7);
    font-size: 17px;
    font-weight: 300;
  }
`

export const JoinWaitlistBtn = styled.button`
  text-decoration: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 0px 30px;
  background: var(--secondary-color);
  height: 50px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  cursor: pointer;
  font-family: inherit;
  font-size: 20px;
  color: #000;

  &:hover {
    background: var(--primary-color);
    color: #fff;
  }
`
