import React from 'react';
import neuralcode_logo_white from '../../assets/neuralcode_logo_white.png';
import { 
  Bar,
  AppBarContainer,
  BarLogo
} from './AppBarElements';
import styled from 'styled-components';
import { Button } from '../Elements/Button';

const FlexSpacer = styled.div`
  margin-left: ${props => props.isRTL ? '0px' : 'auto'};
  margin-right: ${props => props.isRTL ? 'auto' : '0px'};
`

const AppBar = () => {

  const openInNewTab = (url) => {
    var win = window.open(url, '_blank');
    win.focus();
  };

  return (
    <>
      <Bar>
        <AppBarContainer>
          <BarLogo to="/">
            <img
              src={neuralcode_logo_white}
              height={35}
              alt="NeuralCode"
              style={{pointerEvents: 'none'}}
            />
          </BarLogo>
          <FlexSpacer />
          <Button color='white' outlined padding='10px 20px' borderRadius={10}
            onClick={() => openInNewTab('https://app.useneuralcode.com')}>
            Login
          </Button>
        </AppBarContainer>
      </Bar>
    </>
  )
}

export default AppBar;
