import React from 'react';
import {
  NotFoundContainer,
  NotFoundCard,
  BtnWrap
} from './NotFoundElements';
import { Button } from '../../components/Elements/Button';
import { useNavigate } from 'react-router-dom';
import neuralcode_logo from '../../assets/neuralcode_logo.png';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <NotFoundContainer>
        <NotFoundCard>
          <div>
            <img 
              src={neuralcode_logo}
              height={40}
              alt="NeuralCode"
              style={{pointerEvents: 'none'}}
            />
          </div>
          <div style={{fontSize: '40px', fontWeight: 'bold', marginTop: '0px'}}>
            404
          </div>
          <div style={{fontSize: '20px'}}>
            We couldn't find the page you are looking for.
          </div>
          <BtnWrap>
            <Button elevated borderRadius={30} dark onClick={() => navigate('/')}>
              Back To Home
            </Button>
          </BtnWrap>
        </NotFoundCard>
      </NotFoundContainer>
    </>
  )
}

export default NotFound;
