import React from 'react';
import AppBar from '../../components/AppBar';
import { 
  JumboContainer,
  JumboBg,
  JumboImg,
  JumboDiv,
  JumboContent,
  JumboTitle,
  JumboSubtitle,
  ThirdText
} from './HomeElements';
import neuralcode_jumbo_v1 from '../../assets/neuralcode_jumbo_v1.jpg';
import { Button } from '../../components/Elements/Button';

const Home = () => {

  const openInNewTab = (url) => {
    var win = window.open(url, '_blank');
    win.focus();
  };

  return (
    <>
      <AppBar />
      <JumboContainer>
        <JumboBg>
          <JumboImg src={neuralcode_jumbo_v1} />
        </JumboBg>
        <JumboDiv>
          <JumboContent>
            <JumboTitle>
              Go From Idea To Advanced Software in No Time!
            </JumboTitle>
            <JumboSubtitle>
              Transform the way you build with cutting-edge AI tools designed to accelerate innovation. From code to creation, we've got you covered.
            </JumboSubtitle>
            <ThirdText>
              Early Beta Access Now Open! <b>Join Now!</b>
            </ThirdText>
            <Button color="white" padding="15px 30px" borderRadius={10}
              onClick={() => openInNewTab('https://app.useneuralcode.com')} style={{marginTop: '15px'}}>
              Try NeuralCode Now!
            </Button>
          </JumboContent>
        </JumboDiv>
      </JumboContainer>
      { /* Footer */ }
      <div style={{textAlign: 'center', color: '#fff', marginTop: '5px', padding: '10px 15px', fontWeight: '200'}}>
        Copyright &copy; {new Date().getFullYear()} All Rights Reserved By <span style={{fontWeight: '400'}}>NeuralCode</span>.
      </div>
    </>
  )
}

export default Home;
